<template>
  <v-row v-if="!shouldHideDialog" justify="center">
    <v-dialog
      v-model="showPopup"
      persistent
      max-width="65%"
      style="z-index: 20020 !important;"
    >
      <div style="width: 100%; position: relative;" class="m-auto-o" >
        <vueper-slides 
          ref="vueperSlides"  
          :dragging-distance="50"
          :arrows="false"
          :bullets="true"
          :current-slide="currentImageIndex"
          style="height: 100%;" 
          :touch-dragging="true"  
          :key="slideshowKey"
        >
          <vueper-slide
            v-for="(slide, i) in images"  
            :key="i"  
            :image="slide"
            class="slide-image"
            style="height: 100%; background-size: cover; background-position: center;"
          />
        </vueper-slides>


        <div class="d-flex position-absolute">
          <div class="mr-2"> SKIP {{ remainingTime }}</div>
          <div class="box-x" @click="closePopup">
            <img src="@/assets/arrows/x.svg" alt="Close">
          </div>
        </div>
      </div>
    </v-dialog>
  </v-row>

  <v-row v-else justify="center">
    <v-dialog
      v-model="showPopup"
      persistent
      max-width="100%"
      style="z-index: 20020 !important;"
    >
      <div style="width: 100%; height:100%; position: relative;" class="m-auto-o">
        <img :src="currentImage" class="mb-img" alt="Image" style="width:100%; height: 100%;">
        <div class="d-flex position-absolute">
          <div class="mr-2"> SKIP {{ remainingTime }}</div>
          <div class="box-x" @click="closePopup">
            <img src="@/assets/arrows/x.svg" alt="Close">
          </div>
        </div>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
export default {
  components: {
    VueperSlides,
    VueperSlide
  },
  data() {
    return {
      showPopup: false,
      remainingTime: 6, // Countdown in seconds
      intervalId: null,
      slideshowIntervalId: null,
      currentImageIndex: 0,
      slideshowKey: 0, // Key to force re-rendering
      images: [
        // require('@/assets/carousel/web.jpg'),
        require('@/assets/carousel/ads.jpg')
      ],
      setAdspopup: localStorage.getItem('adspopup')
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentImageIndex];
    },
    shouldHideDialog() {
      // return this.$vuetify.breakpoint.xsOnly;
          return this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.width < 500; 
    },
  },
  watch: {
    currentImageIndex() {
      this.slideshowKey++; // Increment key to force re-render
    }
  },
  methods: {
    startTimer() {
      this.intervalId = setInterval(() => {
        if (this.remainingTime > 1) {
          this.remainingTime--;
        } else {
          this.closePopup();
        }
      }, 1000); // Update every second
    },
    // calculateOddEven(number) {
    //   return number % 2 === 0 ? 0 : 1;
    // },
      resizeVueperSlides() {
    this.$nextTick(() => {
      if (this.$refs.vueperSlides) {
        this.$refs.vueperSlides.$emit('resize');
      }
    });
  },
    startSlideshow() {
      this.slideshowIntervalId = setInterval(() => {
        this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
        this.$nextTick(() => {
          if (this.$refs.vueperSlides) {
            this.$refs.vueperSlides.goToSlide(this.currentImageIndex);
          }
        });
      }, 3000); // Change image every 3 seconds
    },
    stopSlideshow() {
      clearInterval(this.slideshowIntervalId);
    },
    closePopup() {
      clearInterval(this.intervalId);
      this.stopSlideshow();
      this.showPopup = false;
      this.removefixscroll();
    },
    fixscrollshow() {
      document.querySelector('html').classList.add('fix-scroll');
    },
    removefixscroll() {
      document.querySelector('html').classList.remove('fix-scroll');
    },
    showPopupOnlyone() {
      if (this.setAdspopup === '1') {
        this.showPopup = false;
      } else {
        localStorage.setItem('adspopup', '1');
        setTimeout(() => {
          this.startTimer();
          this.startSlideshow();
          this.showPopup = true;
          this.fixscrollshow();
        }, 1500);
      }
    },
    handleBeforeUnload() {
      localStorage.setItem('adspopup', '2');
    }
  },
 
  scrollToTop() {
            window.scrollTo(0,0);
        },
   created() {
    localStorage.setItem('adspopup', '1');
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  mounted() {
    // this.showPopupOnlyone();
    this.showPopupOnlyone();
    window.addEventListener('resize', this.resizeVueperSlides);
  },
  beforeDestroy() {
    // window.removeEventListener('beforeunload', this.handleBeforeUnload);
    // this.stopSlideshow();
    window.removeEventListener('resize', this.resizeVueperSlides); // Clean up event listener
  this.stopSlideshow();
  }
};
</script>


  <style scoped>
    .v-dialog__content--active{
      z-index: 1000000000 !important;
    }
    .width-max{
      max-width: 60%;
    }
    .v-dialog--active{
      margin-top: 10% !important;
    }
    .mb-img{
       margin-bottom: -5px;
    }
    /* .screen-large{
        display: block !important;
    }
    .screen-mobile{
        display: none !important;
    } */
    .position-absolute{
      position: absolute;
      z-index: 9999;
      right: 15px;
      top: 10px;
    }
    .box-x{
      width: 24px;
      height: 24px;
      background: white;
      cursor: pointer;
    }
    .box-x:hover{
      opacity: 1;
    }
    .m-auto-o{
      height: 550px;
    }
    @media (min-width: 1904px) and (max-width: 2500px) {
      .m-auto-o{
        height: 45rem;
        /* width: 140rem; */
      }
      
    }
    @media (min-width: 2500px) and (max-width: 3000px) {
      .m-auto-o{
        height: 62rem;
        /* width: 140rem; */
      }
      
    }
    @media (min-width: 1540px) and (max-width: 1904px) {
      .v-dialog--active{
        max-width: 55% !important;
        margin-top: 10% !important;
      }
    }
    @media (min-width: 1024px) and (max-width: 1200px) {
      .v-dialog--active{
        max-width: 90% !important;
        margin: 20px !important;
      }
      .m-auto-o{
        height: 27rem;
      }
    }

    @media (min-width: 600px) and (max-width: 1024px) {
      .v-dialog--active{
        max-width: 90% !important;
        margin: 20px !important;
      }
      .m-auto-o{
        height: 20rem;
      }
    }
    /* @media screen and (max-width: 500px) { */
    /* @media (width < 600px) { */
        /* .v-dialog--active{
        max-width: 90% !important;
        margin: 20px !important;
      } */
      /* v-dialog{
        width: 10%!important;
        height: 100%!important;
       } */
       /* .m-auto-o{ */
        /* height: 20rem !important; */
        /* width: 30rem !important; */

      /* } */
    /* } */

  </style>